import _styled from "styled-components";
import { CerbosWorkflowAction } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, ButtonType, Icon, TypographyWeight, TypographySize, Typography, TextIconColor, TextField, TextFieldType, TypographyFont, Size, TypographyCasing } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { NavButton } from '~/components/Nav/NavButton';
import { CopyButton } from '~/components/shared';
import { BASE_NEXT_URL, getWorkflowBuilderRoute, ROOT_ROUTE, WORKFLOW_ID_QUERY_PARAM, WORKSTATION_ROUTE } from '~/constants';
import { useAppDispatch, useCanShareWorkflow, useToast, ToastType } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
const WorkstationHeaderContainer = _styled.div(() => [{
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(22 18 61 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}, isMobileOnly && {
  "marginTop": "1rem"
}]);
const BadgeAndUpdatedAtContainer = _styled.div({
  "display": "flex",
  "flexShrink": "0",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "1.25rem"
});
const ActionButtonsContainer = _styled.div({
  "display": "flex",
  "flexShrink": "0",
  "alignItems": "center",
  "gap": "0.5rem"
});
const DescriptionAndActionsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "0.5rem"
});
const TitleAndBadgeContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "minWidth": "0px",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "1rem"
});

// Custom styled TextField wrapper used only for this component
// Includes transition animations for the text field and the icon
const TextFieldContainer = _styled.div`
  .MuiInputAdornment-root {
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }

  .MuiInput-input {
    transform: translateX(-24px);
    transition: transform 0.2s ease-out;
    border-bottom: 1px solid transparent;
  }

  &:focus-within {
    .MuiInput-input {
      border-bottom: 1px solid #8d76ff;
    }
  }

  &:hover,
  &:focus-within {
    .MuiInputAdornment-root {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }

    .MuiInput-input {
      transform: translateX(0);
    }
  }
`;
interface WorkstationHeaderProps {
  chatId: string | undefined;
  refetchChat: () => void;
  title: string;
  workflow: Workflow | undefined;
}
const WorkstationHeader: React.FC<WorkstationHeaderProps> = ({
  chatId,
  refetchChat,
  title,
  workflow
}) => {
  const workflowId = workflow?.id;
  const [chatTitle, setChatTitle] = useState(title);

  // Redux
  const dispatch = useAppDispatch();
  const {
    enqueueToast
  } = useToast();
  const openShareWorkflowModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_WORKFLOW,
      workflowId: workflowId ?? ''
    }));
  };
  const openShareChatModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_CHAT,
      chatId: chatId ?? ''
    }));
  };

  // Custom hooks
  const router = useRouter();
  const {
    canShareWorkflow
  } = useCanShareWorkflow(workflowId);

  // Queries
  const {
    data: isAllowedToEditWorkflow
  } = nextTrpc.workflows.isAllowed.useQuery({
    id: workflowId ?? '',
    action: CerbosWorkflowAction.EDIT_WORKFLOW
  }, {
    enabled: !!workflowId
  });

  // Mutations
  const updateTitleMutation = nextTrpc.chats.updateTitle.useMutation({
    onMutate: data => {
      setChatTitle(data.title);
    },
    onSuccess: () => {
      refetchChat();
      enqueueToast({
        message: 'Title updated successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      setChatTitle(title);
      enqueueToast({
        message: 'An error occurred while updating the title',
        type: ToastType.ERROR
      });
    }
  });

  // Handlers
  const handleTitleChange = (newTitle: string) => {
    if (!chatId || newTitle.trim() === title) {
      return;
    }
    if (newTitle.trim() === '') {
      setChatTitle(title);
      return;
    }
    updateTitleMutation.mutate({
      chatId,
      title: newTitle
    });
  };
  const handleRestartWorkflow = () => {
    // Remove chatId if it exists
    const {
      chatId: _paramChatId,
      ...query
    } = router.query;
    void router.push({
      pathname: router.pathname,
      query
    });
  };
  const shareWorkflowLink = workflowId ? `${BASE_NEXT_URL}${WORKSTATION_ROUTE}?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}` : '';
  const isPublic = !!workflow?.publicRole;
  const renderActionButtons = () => <ActionButtonsContainer>
      {/* If the user doesn't have permission to share */}
      {/* But the workflow is public, let them copy the link */}
      {workflowId && isPublic && !canShareWorkflow && <CopyButton data-id="workflow_copy" icon={Icon.SHARE} label="Share Agent" successText="Agent Link Copied" text={shareWorkflowLink} />}
      {!!workflowId && <>
          {canShareWorkflow && <Dropdown data-id="workflow_share" trigger={{
        buttonProps: {
          startIcon: Icon.SHARE,
          label: 'Share',
          size: Size.SMALL,
          type: ButtonType.OUTLINED
        },
        hideChevron: true
      }}>
              <DropdownItem onClick={() => openShareWorkflowModal()} title="Share Agent" />
              <DropdownItem onClick={() => openShareChatModal()} title="Share Chat" />
            </Dropdown>}
          <Button data-id="workflow_restart" endIcon={Icon.RELOAD} label="Restart" onClick={handleRestartWorkflow} size={Size.SMALL} type={ButtonType.OUTLINED} />
          {isAllowedToEditWorkflow && <Button data-id="workflow_edit" endIcon={Icon.EDIT} href={getWorkflowBuilderRoute(workflowId)} label="Edit Agent" size={Size.SMALL} type={ButtonType.OUTLINED} />}
        </>}

      {isMobileOnly && <NavButton data-id="nav_new_chat" hideActive icon={Icon.RELOAD} route={ROOT_ROUTE} />}
    </ActionButtonsContainer>;
  return <WorkstationHeaderContainer>
      <TitleAndBadgeContainer>
        <TextFieldContainer css={{
        "flex": "1 1 0%"
      }}>
          <TextField font={TypographyFont.INTERACTIVE} fullWidth onBlur={() => handleTitleChange(chatTitle)} onChange={value => setChatTitle(value)} placeholder="ENTER A NEW TITLE" size={TypographySize.MEDIUM} startIcon={Icon.EDIT} startIconFocusColor={TextIconColor.HIGHLIGHT} textWeight={TypographyWeight.MEDIUM} type={TextFieldType.SIMPLE} value={chatTitle.toLocaleUpperCase()} />
        </TextFieldContainer>
        <BadgeAndUpdatedAtContainer>
          {!workflowId && !!chatId && <Button data-id="chat_share" endIcon={Icon.SHARE} label="Share" onClick={openShareChatModal} size={Size.SMALL} type={ButtonType.OUTLINED} />}
          {workflow?.category && <Badge label={workflow?.category} />}
          {workflow?.updatedAt && <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
              {formatDistanceToNowStrict(new Date(workflow?.updatedAt), {
            addSuffix: true
          })}
            </Typography>}
        </BadgeAndUpdatedAtContainer>
      </TitleAndBadgeContainer>
      {!!workflowId && <DescriptionAndActionsContainer>
          {workflow?.description && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
              {workflow.description}
            </Typography>}
          {renderActionButtons()}
        </DescriptionAndActionsContainer>}
    </WorkstationHeaderContainer>;
};
export default WorkstationHeader;